// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  //productName: window.location.host,
  productName: 'Marksharks',
  productUrl: 'https://www.marksharks.com',
  production: false,
  //apiUrl: 'http://localhost:4001/api',
  apiUrl: 'https://dev.marksharksplus.com:4004/api',
  //apiUrl: 'https://support.marksharks.com:4004/api',
  //onlineClassUrl: 'https://dev.marksharksplus.com/dev_online_class/index.php',
  onlineClassUrl: 'https://marksharks.com/online_class/index.php',
  //liveUrl: 'http://localhost:4200',
  //liveUrl: 'https://dev.marksharksplus.com:4004',
  liveUrl: 'https://dev.marksharksplus.com',
  //liveUrl: 'https://support.marksharks.com',
  // liveUrl: 'https://34.93.238.81',
  imgUrl: 'https://storage.googleapis.com/lms-app',
  adminUrl: 'https://dev.marksharksplus.com:4004',
  msUrl: 'https://storage.googleapis.com/marksharks-content',
  contentUrl: 'https://storage.googleapis.com/marksharks-content/msplus-content',
  appContentUrl: 'https://storage.googleapis.com/marksharks-content/app-content',
  API_URL: 'https://www.googleapis.com/youtube/v3/search',
  API_TOKEN: 'AIzaSyDmNbqZfOxBYq3rxE-hy5qIwqhGyqGX_dg',
  quesUploadUrl: 'http://ec2-3-95-207-0.compute-1.amazonaws.com/LMS/convert_html.php',
  crmUrlApi: 'https://mcrm.marksharks.com:3005/api',
  razorPayKey: 'rzp_test_h6gvOZC5j1bCuu'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
